<template>
  <div>
    <HomeHeader />
    <!-- <pillars @goTo="goTo" /> -->
    <!-- <gallery-highlight /> -->
    <shop />
    <!-- <highlighted />
    <features ref="features" /> -->
    <!-- <testimony /> -->
  </div>
</template>

<script>
import HomeHeader from "../../components/home/Header.vue";
import Pillars from "../../components/home/Pillars.vue";
import Highlighted from "../../components/home/Highlighted.vue";
import Features from "../../components/home/Features.vue";
import GalleryHighlight from "../../components/home/GalleryHighlight.vue";
import Shop from "../../views/shop/Index.vue";
// import Testimony from "../../components/home/Testimony.vue";
export default {
  metaInfo: {
    title: "Home",
  },
  components: {
    HomeHeader,
    Pillars,
    Highlighted,
    Features,
    GalleryHighlight,
    Shop,
  },
  name: "Home",
  methods: {
    goTo({ hash }) {
      this.$refs.features.goTo(hash);
      const el = this.$refs.features;
      this.$vuetify.goTo(el, {
        offset: 30,
        behavior: "smooth",
      });
    },
  },
};
</script>
